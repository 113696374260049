.section_faqs {
    position: relative;
    margin: auto;

    .faqs_container {
        margin-bottom: 4rem;

        .faqs_heading {
            text-align: center;
            font-size: 38px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            margin: 2rem 0;
        }

        .faqs_body {
            margin: 0 2%;
            margin-top: 25px;
            margin-bottom: 50px;
            border: 1px solid black;
            padding: 0 40px 0;
            border-radius: 12px;

            .faqs_text {
                text-align: center;
                font-size: 38px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;
                margin: 2rem 0;
            }
        }
    }
}

.section_termOfServices {
    .termOfServices_container {
        padding: 0 2%;
        margin-top: 25px;
        margin-bottom: 70px;

        .termServicesHeading {
            text-align: center;
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            margin: 2rem 0 4rem;
        }

        .termOfServicesPrimaryHeading {
            font-size: 18px;
            line-height: 35px;
            text-align: justify;
            display: inline-block;
            font-weight: 700;
        }

        .termOfServicesText {
            font-size: 18px;
            line-height: 35px;
            text-align: justify;
            display: inline-block;
        }

        .termOfServicesListing {
            font-size: 18px;
            line-height: 35px;
            text-align: justify;
            display: inline-block;
        }
    }
}

.section_privacy_policy {
    .section_privacy_container {
        padding: 0 2%;
        margin-top: 25px;
        margin-bottom: 70px;

        h1 {
            font-family: "Poppins-Bold";
            font-size: 38px;
            text-align: center;
            color: #001426;
            margin-bottom: 12px;
        }

        .privacyPolicyHeading {
            text-align: center;
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            margin: 2rem 0 4rem;
        }

        .termAndConditionContainer {
            border: 1px solid black;
            border-radius: 12px;
            padding: 18px 24px;

            .termAndConditionContent {
                &.termAndConditionText {
                    .termAndConditionMainHeading {
                        font-family: "Poppins-bold";
                        font-size: 21px;
                        line-height: 35px;
                        color: #001426;
                        margin-bottom: 15px;
                        margin-top: 30px;
                        text-align: justify;
                    }

                    .termAndConditionSecondHeading,
                    .termAndConditionPrimaryHeading,
                    .termAndConditionTextArea {
                        font-family: "Poppins-Regular";
                        font-size: 18px;
                        line-height: 35px;
                        color: #001426;
                        text-align: justify;
                        display: inline-block;
                    }

                    .termAndConditionSecondHeading {
                        margin: 15px 0;
                    }

                    .listing {
                        list-style-position: outside;
                        margin-left: 35px;

                        li {
                            margin-bottom: 10px;
                            line-height: 35px;
                            font-size: 18px;
                            text-align: justify;
                            display: inline-block;
                        }
                    }

                    .termAndConditionBottomtext {
                        .termAndConditionTextArea {
                            font-family: "Poppins-Regular";
                            font-size: 18px;
                            line-height: 35px;
                            color: #001426;
                            text-align: justify;
                        }
                    }
                }
            }
        }
    }
}

.section_guidelines {
    .guidelines_container {
        padding: 0 2%;
        margin-top: 25px;
        margin-bottom: 70px;

        .ruleGuideLinesHeading {
            text-align: center;
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            margin: 2rem 0 4rem;
        }

        h1 {
            font-family: "Poppins-Regular";
            font-size: 38px;
            text-align: center;
            color: #001426;
            margin-bottom: 25px;
        }

        .RuleAndGuideLinesContainer {
            border: 1px solid black;
            border-radius: 12px;
            padding: 18px 24px;
            text-align: justify;
            line-height: 35px;

            .RuleGuideLinesContentContainer {
                margin-bottom: 35px;

                .RuleGuideLinesPrimaryHeading,
                .RuleGuideLinesSecondryHeading {
                    font-family: "Poppins-bold";
                    font-size: 21px;
                    line-height: 35px;
                    color: #001426;
                    margin-bottom: 15px;
                    text-align: justify;
                }

                .RuleAndGuideText {
                    .RuleAndGuideParagraph {
                        font-family: "Poppins-Regular";
                        font-size: 18px;
                        line-height: 35px;
                        color: #001426;
                        margin: 15px 0;
                        text-align: justify;
                    }
                }

                .listing {
                    list-style-position: outside;
                    margin-left: 35px;
                    font-family: "Poppins-Regular";
                    font-size: 18px;
                    line-height: 35px;
                    color: #001426;
                    text-align: justify;

                    li {
                        margin-bottom: 10px;
                        text-align: justify;
                        line-height: 35px;
                    }
                }
            }
        }
    }
}

.section_our_solutions {

    .our_solutions_container {
        .quote {
            text-align: center;
            font-size: 38px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            margin: 2rem 0 4rem;
        }

        .solutionsBody {
            .body {
                margin: 0 2%;
                margin-top: 25px;
                margin-bottom: 50px;
                border: 1px solid black;
                padding: 0 40px 24px;
                border-radius: 12px;

                .solutionWrapper {
                    // styles for each solution wrapper

                    .primaryHeading {
                        margin-bottom: 12px;
                        font-family: "Poppins-Regular";
                        margin-top: 45px;
                        line-height: 35px;
                        font-size: 18px;
                    }

                    .secondaryHeading {
                        font-family: "Poppins-bold";
                        margin-top: 45px;
                        line-height: 35px;
                        margin-bottom: 5px;
                        font-size: 18px;
                    }

                    .solutions {
                        list-style-position: inside;
                        margin-left: 20px;
                        line-height: 35px;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

.section_about_us {
    .about_us_container {
        .quote {
            text-align: center;
            font-size: 38px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            margin: 2rem 0 4rem;
        }

        .aboutBody {
            margin: 0 2%;
            margin-top: 25px;
            margin-bottom: 50px;
            border: 1px solid black;
            padding: 40px 24px;
            border-radius: 12px;

            .aboutHeading {
                text-align: center;
                font-size: 38px;
                font-weight: 700;
                margin-bottom: 2rem;
            }

            .body {
                font-size: 18px;
                line-height: 35px;
                text-align: justify;
                display: inline-block;
            }
        }
    }
}