.registration_section {
    background-position: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../images/Banner.gif");
    padding: 3% 5% 3%;

    .registration_sectionContainer {
        display: flex;

        .registration_sidebar {
            background-image: linear-gradient(to right, #00417b, #003665, #002a4f, #001f3a, #001426);
            border-radius: 30px 0 0 30px;
            position: relative;
            width: 20vw;
            background-color: var(--primary);

            .registration_sidebarContainer {
                position: relative;
                top: 120px;

                .registration_navMenuContainer {
                    position: relative;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .registration_text {
                        margin: 25px;
                        text-align: right;
                        width: 72%;
                        font-size: 24px;
                        color: white;
                    }

                    .registration_count {
                        position: absolute;
                        right: 0;
                        background: white;
                        width: 65px;
                        height: 65px;
                        padding: 10px;
                        font-size: 29px;
                        border-radius: 50%;
                        text-align: center;
                        cursor: pointer;
                        transform: translateX(50%);
                    }
                }
            }
        }

        .registration_mainArea {
            flex: 1;
            padding: 50px 60px;
            background-color: rgba(222, 235, 241, 0.9);
            border-radius: 0 30px 30px 0;
            min-height: 50vw;
        }
    }
}

.active {
    font-weight: bold;
}

.modalLayoutWrapper {
    position: relative;
    padding: 25px 30px;
}

.modalLayoutBodyWrapper {}

.makeScrollable {
    width: 100%;
    height: 85vh;
    overflow: auto;
    margin-right: 5px;
}

.closeBtn {
    display: inline-block;
    background: #ffffff;
    position: absolute;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    right: -18px;
    top: -16px;
    cursor: pointer;
    border: 2px solid black;
}

.closeBtn span {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modalSm {
    max-width: '700px';
    width: '100%';
}

.modalMd {
    max-width: '700px';
    width: '100%';
}

.modalLg {
    max-width: '1000px';
    width: '100%';
}

.modalXl {
    max-width: '1200px';
    width: '100%';
}

.Completed {
    box-shadow: inset 0px 0px 0px 7px #31bc10;
}

.InComplete {
    box-shadow: inset 0px 0px 0px 7px #ffce00;
}



.navTabs {
    background-color: transparent;
    display: flex;
    border-bottom: 1px solid black;
    cursor: pointer;
    position: relative;
}

.navItem {
    width: 33.3%;
    text-align: center;
    padding: 10px 0;
    font-family: 'Poppins-Medium';
    display: flex;
    justify-content: center;
}

.navItem.active {
    border-bottom: 3px solid black;
}

.active .navLink {
    font-weight: bold;
    margin-left: 10px;
}

.tcContainer {
    font-family: 'Poppins-Regular';
}

.termsInfo {
    font-size: 15px;
    line-height: 2;
    padding: 0px 20px;
    text-align: "justify";
    font-family: "Poppins-Regular";
}

.textHeading {
    margin: 0;
}

.listSubHeading {
    margin-bottom: 10px;
}