*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}

html,
body {
    max-width: 100vw;
    overflow-x: hidden;
}

/* CSS Variables */
:root {
    --text-white: #ffffff;
    --text-black: #000000;
    --primary: #001426;
    --secondary: #DEEBF1;
    --grey-primary: #cccccc;
    --grey-secondary: #f0f0f0;
    --grey-tertiary: #f7f7f7;

    --font-black: 'Poppins-Black';
    --font-black-italic: 'Poppins-BlackItalic';
    --font-bold: 'Poppins-Bold';
    --font-bold-italic: 'Poppins-BoldItalic';
    --font-extra-bold: 'Poppins-ExtraBold';
    --font-extra-bold-italic: 'Poppins-ExtraBoldItalic';
    --font-extra-light: 'Poppins-ExtraLight';
    --font-extra-light-italic: 'Poppins-ExtraLightItalic';
    --font-italic: 'Poppins-Italic';
    --font-light: 'Poppins-Light';
    --font-light-italic: 'Poppins-LightItalic';
    --font-medium: 'Poppins-Medium';
    --font-medium-italic: 'Poppins-MediumItalic';
    --font-regular: 'Poppins-Regular';
    --font-semi-bold: 'Poppins-SemiBold';
    --font-semi-bold-italic: 'Poppins-SemiBoldItalic';
    --font-thin: 'Poppins-Thin';
    --font-thin-italic: 'Poppins-ThinItalic';
}

/* Font Faces */
@font-face {
    font-family: 'Poppins-Black';
    src: url('./../fonts/Poppins-Black.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-BlackItalic';
    src: url('./../fonts/Poppins-BlackItalic.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('./../fonts/Poppins-Bold.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-BoldItalic';
    src: url('./../fonts/Poppins-BoldItalic.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-ExtraBold';
    src: url('./../fonts/Poppins-ExtraBold.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-ExtraBoldItalic';
    src: url('./../fonts/Poppins-ExtraBoldItalic.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-ExtraLight';
    src: url('./../fonts/Poppins-ExtraLight.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-ExtraLightItalic';
    src: url('./../fonts/Poppins-ExtraLightItalic.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-Italic';
    src: url('./../fonts/Poppins-Italic.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('./../fonts/Poppins-Light.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-LightItalic';
    src: url('./../fonts/Poppins-LightItalic.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('./../fonts/Poppins-Medium.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-MediumItalic';
    src: url('./../fonts/Poppins-MediumItalic.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('./../fonts/Poppins-Regular.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./../fonts/Poppins-SemiBold.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-SemiBoldItalic';
    src: url('./../fonts/Poppins-SemiBoldItalic.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-Thin';
    src: url('./../fonts/Poppins-Thin.ttf?v=1.0.0');
}

@font-face {
    font-family: 'Poppins-ThinItalic';
    src: url('./../fonts/Poppins-ThinItalic.ttf?v=1.0.0');
}