main {
    margin-top: 75px !important;
}

.dashboard-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000080;
    inset: 0;
    z-index: 9 !important;

    .dashboard-loader-icon-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.section_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 32px;
    background: var(--secondary);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;

    .section_heading_container {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .logo-container {
            max-width: 15%;
            min-width: 10%;
            width: 170px;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }

        .before-login-option-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 40px;

            .navigation-links {
                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    gap: 30px;

                    li {
                        cursor: pointer;
                    }
                }
            }

            .navigation-buttons {
                display: flex;
                gap: 20px;
            }
        }

        .after-login-option-container {
            display: flex;
            gap: 15px;
            align-items: center;

            .profile {
                display: flex;
                align-items: center;
                position: relative;
                cursor: pointer;
            }

            .profile-card {
                position: absolute;
                width: 200px;
                height: 100px;
                padding: 10px;
                background: var(--white);
                right: 5%;
                top: 13.5%;
                z-index: 1000;
                border-radius: 10px;
                border: 1px solid #b4b4b4;
                color: var(--primary);

                .row1 {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                }
            }

            .overlay {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1000;
            }
        }
    }
}

.footer {
    background-color: var(--secondary);
    padding: 3% 5% 0;

    .section-footer {
        gap: 10%;
        display: flex;
        justify-content: space-between;

        .logo-container {
            width: 250px;
            max-width: 15%;

            .logo-wall {


                img {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
            }
        }

        nav {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            .list-item-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: var(--text-brand);
                list-style: none;
                flex-grow: 1;
                margin-bottom: 0;

                span {
                    cursor: pointer;
                }
            }

        }
    }

    .horizontalLine {
        width: 100%;
        max-width: 1900px;

        .line {
            color: white;
            height: 2px;
            width: 100%;
            padding: 25px 0px;
            border-bottom: 1px solid #00000040;
        }
    }

    .copyright {
        text-align: center;
        color: var(--text-brand);
        padding: 15px 0;

        .copyrightText {
            line-height: 35px;
            display: inline-block;
        }
    }
}

.sidebar {
    width: 20vw;
    background-color: var(--primary);

    ul {
        padding: 0;

        .linkList {
            position: relative;
            padding: 18px;
            display: flex;
            align-items: center;
            gap: 10px;
            color: var(--white);
            cursor: pointer;
            padding-left: 30px;

            a {
                color: white;
                font-size: 18px;
                text-decoration: none;
            }
        }

        .pipe {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0.5rem;
            background: #f47a30 !important;
        }

        .active {
            background: #033465;
            color: var(--white);
            font-weight: bold;
        }
    }
}

.dashboard-container {
    width: 80%;
    padding: 5px 20px 20px 20px;
}

.cards {
    width: 100%;
    max-width: 400px;
    height: 165px;
    background-image: url("../images/dashboard/cardBg.svg");
    background-color: #002b50;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    margin: 0 auto;
    flex-direction: column;

    .cardNumber {
        color: white;
        font-size: 38px;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 0.9;
    }

    .card-icon-container {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;

        .cardIcon {
            width: 100%;
        }
    }

    .cardText {
        color: white;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0;
        width: 100%;
        text-align: center;
    }
}

.cardGroup {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* Default to 1 column on mobile */
    gap: 20px;
    /* Spacing between cards */
    margin: 10px 0px 20px 0px;
}

@media (min-width: 768px) {
    .cardGroup {
        grid-template-columns: repeat(2, 1fr);
        /* 2 columns on tablet */
    }
}

@media (min-width: 1024px) {
    .cardGroup {
        grid-template-columns: repeat(3, 1fr);
        /* 3 columns on desktop */
    }
}

.breadContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .currentPage {
        color: #001426;
        font-size: 1.3rem;
        font-weight: 600;
    }
}

.breadcrumb {
    margin: 0px !important;
}

.btn-custom {
    background-color: #7adbd7;
    border-radius: 8px;
    color: #002b50;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-weight: 600;
}

.btn-custom:hover {
    background-color: #66c5c1;
    color: #002b50;
}

.textFieldPlane {
    display: block;
    width: 60%;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 0 13px;
    color: #000000d9;
    border-bottom: 1px solid #707070;
}

.simpleTextFieldPlane {
    display: block;
    width: 60%;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 0 13px;
    color: #000000d9;
    border-bottom: 1px solid #707070;
}

.textField,
.passwordField {
    display: block;
    width: 100%;
    border-radius: 6px;
    border: none;
    outline: none;
    line-height: 2.5;
    font-size: 16px;
    padding: 0 13px;
    color: var(--black);
    box-shadow: 3px 5px 10px #00000034;
    border: 2px solid #ffffff;
}

.textField:focus,
.passwordField:focus {
    border: 2px solid #284460;
}

.passwordField:disabled,
.textareaField:disabled,
.textField:disabled {
    background-color: var(--disable);
}

.simpleTextField {
    display: block;
    width: 100%;
    border-radius: 6px;
    outline: none;
    line-height: 2;
    font-size: 16px;
    padding: 0 8px;
    color: var(--black);
    border: 2px solid #00000036;
}

.simpleTextField:focus {
    border: 2px solid black;
}

.simpleTextField:disabled {
    background-color: var(--disable);
}

.textareaField {
    display: block;
    width: 100%;
    border-radius: 6px;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 10px;
    color: var(--black);
    box-shadow: 3px 5px 10px #00000034;
    border: 2px solid #ffffff;
}

.text-area-count {
    display: flex;
    justify-content: end;
    margin-top: 0.5rem;
    color: var(--black);

}

.textareaField:focus {
    border: 2px solid #284460;
}

.formLabel {
    margin-bottom: 3px;
    color: var(--black);
    opacity: 0.85;
}

.viewLabel {
    // margin-bottom: 0.2rem;
    color: var(--primary);
    font-weight: 500;
    opacity: 1;
}

.textValue {
    color: var(--primary);
    opacity: 0.85;
    padding: 5px 0px;
}

.selectFieldPlane {
    display: block;
    width: 60%;
    outline: none;
    border: none;
    border-bottom: 1px solid #707070;
}

.selectField {
    display: block;
    width: 100%;
    border-radius: 6px;
    outline: none;
    border: none;
    box-shadow: 3px 5px 10px #00000034;
}

.custom-btn {
    background-color: white;
    font-weight: 600;
    padding: 8px 30px;
    border-radius: 5px;
    border: 2px solid black;
    transition:
        background-color 0.3s ease,
        color 0.3s ease;
    cursor: pointer;
}

.custom-btn:hover {
    background-color: black;
    color: white;
}

.mandatoryLbl {
    color: var(--form-mandatory-color);
    font-size: 18px;
    margin-left: 3px;
}

.validationError {
    color: red;
    margin-top: 2px;
    margin-bottom: 5px;
    font-size: 0.8em;
    visibility: visible;
}

.validationSuccess {
    // color: red;
    margin-top: 2px;
    margin-bottom: 5px;
    font-size: 0.8em;
    visibility: hidden;
}

.uploaded-cocument {
    min-height: 0rem;
    max-height: 20rem;
    width: 100%;
    overflow: auto;
    margin: 10px 0px;
}

.bg-infos {
    background-color: rgb(213, 234, 241);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 10px;
}

.pwd {
    padding-right: 12%;
}

.eyeIcon {
    position: absolute;
    top: 0;
    right: 4%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.radioField {
    margin: 0 10px;
    display: inline-block;
}

.radioField input[type="radio"] {
    display: none;
}

.radioField label {
    position: relative;
    display: inline-block;
    padding: 3px 3px 3px 20px;
    font-size: 16px;
    font-family: "Poppins-Regular";
    cursor: pointer;

    @media only screen and (min-width: 950px) and (max-width: 1280px) {
        font-size: 14px !important;
    }
}

.radioField label::before,
.radioField label::after {
    position: absolute;
    content: "";
    top: 50%;
    border-radius: 100%;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.radioField label::before {
    left: 0;
    width: 14px;
    height: 14px;
    margin-top: -8px;
    background: #f3f3f3;
    border: 1px solid #284460;
}

.radioField label:hover::before {
    background: #fff;
}

.radioField label::after {
    opacity: 0;
    left: 3px;
    width: 8px;
    height: 8px;
    margin-top: -5px;
    background: #284460;
    -webkit-transform: scale(2);
    transform: scale(2);
}

.radioField input[type="radio"]:checked+label::before {
    background: #ffffff;
    border: 1px solid #284460;
}

.radioField input[type="radio"]:checked+label::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.sectionMain {
    background-position: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../images/Banner.gif");
    padding: 3% 5% 3%;
    margin-top: 75px;

    .loginWrapper {
        width: 40%;
        background-color: #dfecf3;
        margin: auto;
        padding: 40px 50px;
        border-radius: 30px;


        .formContainer {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 25px;

            .loginPlaceholderContainer {
                margin: auto;
                text-align: center;
            }

            .inputContainerFull {
                width: 100%;
            }

            .loginHeading {
                text-align: center;
                margin-bottom: 35px;
            }

            .inputContainer {
                width: 100%;
                margin-bottom: 20px;
                position: relative;
            }

            .radioContainer {
                display: inline-block;
            }

            .registrationContainer {
                margin-left: 10px;
            }

            .forgetPassword {
                text-align: right;
                text-decoration: underline;

                span {
                    cursor: pointer;
                }
            }
        }

        .buttonContainer {
            flex-direction: column;
            align-items: center;
            gap: 10px;
            display: flex;
            margin-top: 30px;
        }
    }
}

.my-dropdown {
    .profile-dropdown {
        border: none !important;
        background-color: transparent !important;
        display: inline-block !important;
        padding: 0 !important;
        margin: 0 !important;

        &::after {
            display: none !important;
        }

        .profile-icon-container {
            display: inline-block;
            width: 35px;
            height: 35px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .dropdown-menu {
        width: 200px;
        padding: 0;

        .dropdown-item {
            padding: 5px 10px;

            &:active {
                background-color: #001426;
                color: #ffffff;
            }

            .profile-dropdown-menu-options {
                display: flex;
                gap: 20px;
                align-items: center;
                padding: 5px 2px;

                .custom-icon-container {
                    .custom-icon {
                        background-color: #ddebf1;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #001426;
                    }
                }
            }
        }
    }
}

.icon-container {
    cursor: pointer;
}

.breadcrumb-navigation {
    margin-top: 10px;
}

.service_request_confirmation_modal_body_container {
    padding: 35px 10px;
    text-align: center;

    .service_request_heading {
        margin-bottom: 0;
        line-height: 1.3;
    }

    .service_request_heading_confirmation {
        margin-bottom: 0;
        padding-top: 30px;
    }

    .service_request_btn_container {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 30px;
    }
}

.request_placed_successful_modal_body_container {
    padding: 0 0 25px;
    text-align: center;

    .request_placed_successful_img_container {
        width: 200px;
        height: 200px;
        margin: auto;

        img {
            width: 100%;
        }
    }

    .request_placed_successful_heading {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 1.7;
    }

    .request_placed_successful_btn_container {
        display: flex;
        justify-content: center;
    }
}

.view-container {
    border-radius: 8px !important;
    padding: 20px !important;
    margin: 20px 0px !important;

}

.prepare-bid-card-header {
    background: var(--secondary) !important;
    border-radius: 8px;
    width: 100%;
    padding: 0.6rem 3rem;
    display: block;
}

.prepare-bid-card-body {
    background-color: var(--grey-tertiary);
    display: flex;
    flex-direction: column;
    padding: 10px 3rem;
}

.breadup-content {
    background-color: var(--grey-tertiary);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0px 3rem;

    .proposed {
        width: 100%;
        border-radius: 5px;
        border: none;
        outline: none;
        line-height: 2.3;
        font-size: 16px;
        padding: 0 10px;
        color: var(--black);
        border: 1px solid var(--primary);

    }

    .lead-engineer {
        display: flex;
        gap: 1rem;

        .engineer {
            // width: 10rem;
            border-radius: 5px;
            border: none;
            outline: none;
            font-size: 16px;
            color: var(--black);
            border: 1px solid var(--primary);
        }
    }

}

.text-center-y {
    display: flex;
    align-items: center;
}


.request-card {
    background-color: var(--grey-tertiary) !important
}



.currency-container {
    text-align: right;
    margin-top: 10px;
    margin-bottom: 5px;

    .currency-details {
        margin-bottom: 0;
        font-weight: 600;

        .selected-currency {}
    }
}

.logout-confirmation-modal-header{
    font-weight: 800;
    font-size: 18px;
}

.logout-confirmation-modal-body{
    h5 {}
}

.logout-confirmation-modal-btn-container{
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
}

.bid-quote-dropdown{
     cursor: pointer;
     
     option {
        cursor: pointer;
     }
}

.cursor-pointer {
    cursor: pointer;
}