.primary_filled_btn_container {
    border-radius: 4px;
    padding: 12px 30px;
    background-color: #000000d9;
    transition: all 0.4s ease;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid #000000d9;
    color: #ffffff;
    font-weight: 500;

    &:disabled {
        background-color: #eaecef;
        border: 1px solid #eaecef;
        color: #666;
        cursor: not-allowed;
    }
}

.secondary_bordered_btn_container {
    border-radius: 4px;
    padding: 12px 30px;
    background-color: #ffffff;
    transition: all 0.4s ease;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid #000000d9;
    color: black;
    font-weight: 500;

    &:hover {
        background-color: #000000d9;
        border: 1px solid #000000d9;
        color: #ffffff;
    }

    &:disabled {
        background-color: #eaecef;
        border: 1px solid #eaecef;
        color: #666;
        cursor: not-allowed;
    }
}

.tertiary_filled_btn_container {
    border-radius: 4px;
    padding: 12px 20px;
    background-color: #7adbd7;
    transition: all 0.4s ease;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: 1px solid #7adbd7;
    color: var(--primary);
    font-weight: 500;

    &:disabled {
        background-color: #eaecef;
        border: 1px solid #eaecef;
        color: #666;
        cursor: not-allowed;
    }
}

.fiter_container {
    display: flex;
    align-items: center;
    gap: 1rem;

    .refresh {
        width: 2.5rem;
        height: 2.5rem;
        background: var(--secondary);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-weight: bold;
        font-size: 1.5rem;
        cursor: pointer;
    }
}

.drawer-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    transition:
        opacity 0.5s ease,
        visibility 0.5s ease;

    &.open {
        visibility: visible;
        opacity: 1;
    }

    .drawer {
        width: 30%;
        height: 100%;
        background-color: #fff;
        padding: 20px;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
        position: relative;
        transform: translateX(100%); // Hidden off-screen
        transition: transform 0.3s ease-in-out;

        &.open {
            transform: translateX(0); // Slide in when open
        }

        .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            background: none;
            border: none;
            font-size: 20px;
            cursor: pointer;

            &:hover {
                color: red;
            }
        }
    }
}

.Toastify__toast-icon {
    display: none !important;
}

.custom-toast-success-container {
    box-shadow: 0px 4px 16px 0px #100b2714 !important;
    background-color: #f6fff9 !important;
    border: 1px solid #48c1b5 !important;
    padding: 13px !important;
    gap: 16px !important;
    border-radius: 12px !important;
}

.custom-toast-error-container {
    background-color: #fff5f3 !important;
    padding: 13px !important;
    gap: 16px !important;
    border-radius: 12px !important;
    border: 1px solid #eb4335 !important;
    box-shadow: 0px 4px 16px 0px #100b2714 !important;
    color: #2f3f53 !important;
}

.custom-toast-info-container {
    background-color: #f5f9ff !important;
    padding: 13px !important;
    gap: 16px !important;
    border-radius: 12px !important;
    border: 1px solid #9dc0ee !important;
    box-shadow: 0px 4px 16px 0px #100b2714 !important;
    color: #2f3f53 !important;
}

.custom-toast-warning-container {
    background-color: #fff8ec !important;
    padding: 13px !important;
    gap: 16px !important;
    border-radius: 12px !important;
    border: 1px solid #f7d9a4 !important;
    box-shadow: 0px 4px 16px 0px #100b2714 !important;
    color: #2f3f53 !important;
}

.custom-toast-media-container {
    min-width: 400px !important;
    word-wrap: break-word !important;
    position: relative !important;
    right: 100px !important;

    @media (max-width: 768px) {
        & {
            right: 10px !important;
            min-width: 350px !important;
            top: 10px !important;
            position: absolute !important;
            padding: 14px 14px !important;
        }
    }

    @media (max-width: 576px) {
        & {
            right: 10px !important;
            top: 10px !important;
            position: absolute !important;
            min-width: 375px !important;
            padding: 14px 14px !important;
        }
    }

    @media (min-width: 769px) {
        & {
            min-width: 400px !important;
        }
    }

    @media (max-width: 480px) {
        & {
            right: 10px !important;
            min-width: 300px !important;
            top: 10px !important;
            position: absolute !important;
            left: 10px !important;
            padding: 12px 12px !important;
        }
    }
}

.toast-close-icon-container {
    color: #979fa9;
    width: 10px;
    height: 10px;
}

.custom-toast-heading {
    margin-bottom: 4px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 19.6px !important;
    letter-spacing: 0.05em !important;
    text-align: left !important;
    color: #1d1d1f !important;
}

.custom-toast-body {
    margin: 0 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 19.2px !important;
    letter-spacing: 0.05em !important;
    text-align: left !important;
    color: #818181 !important;
}

.toast-content-container {
    display: flex;
    align-items: flex-start;
}

.toast-icon-container {
    margin-right: 18px;
}

.success-toast-icon-container {
    background: #818181;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    color: #ffffff;
    display: inline-block;
}

.info-toast-icon-container {
    background: #1a73e8;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    color: #ffffff;
    display: inline-block;
}

.warning-toast-icon-container {
    background: #fc8019;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    color: #ffffff;
    display: inline-block;
}

.error-toast-icon-container {
    background: #eb4335;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    color: #ffffff;
    display: inline-block;
}

.toast-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
}

.disableMobileField input:disabled {
    background-color: unset !important;
    border: 2px solid #ffffff !important;
}

.checkboxField {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin: 0 10px 0 0;
}

.checkboxField input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkboxIcon {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 8px;
    border: 1px solid #000;
    background-color: white;
    position: relative;
}

.checkboxField .checkboxLabel {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.checkboxField input[type="checkbox"]:checked+.checkboxLabel .checkboxIcon {
    background-color: #001426;
    border-color: #001426;
}

.checkboxIcon::after {
    content: "\2713";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    color: #fff;
    display: none;
}

.checkboxField input[type="checkbox"]:checked+.checkboxLabel .checkboxIcon::after {
    display: block;
}

.tooltipContainer {
    position: relative;
    display: inline-block;
}

.tooltipIcon {
    cursor: pointer;
}

.pillsContainer {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
}

.pillsWrapper {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    background-color: white;
    border: 3px solid black;
    box-shadow:
        0 3px 6px rgba(0, 0, 0, 0.16),
        0 3px 6px rgba(0, 0, 0, 0.23);
    transition: transform 0.15s;
    position: "relative";
}

.pillsWrapper.active {
    width: 60px;
    height: 60px;
    color: white;
    background-color: black !important;
    border: 3px solid black;
    box-shadow:
        0 3px 6px rgba(0, 0, 0, 0.16),
        0 3px 6px rgba(0, 0, 0, 0.23);
    transform: scale(0.92);
}

.closeIcon {
    position: absolute;
    right: -21px;
    top: -14px;
    font-size: 23px;
    color: #7f8081;
    cursor: pointer;
}

.pill {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Remove number input arrows for Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

// src/components/common/FormControls/FileUploadField/index.scss
.file-upload-container {
    position: relative;
    width: 100%;

    .form-label {
        margin-bottom: 0.5rem;
        display: block;
    }

    .file-upload-wrapper {
        border: 2px dashed #d1d5db;
        border-radius: 8px;
        padding: 20px;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            border-color: #3b82f6;
            background-color: #f3f4f6;
        }

        &.is-invalid {
            border-color: #dc2626;
        }

        &.disabled {
            cursor: not-allowed;
            opacity: 0.6;
            pointer-events: none;
        }
    }

    .file-upload-placeholder {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #6b7280;

        svg {
            color: #3b82f6;
            margin-bottom: 10px;
        }

        p {
            margin: 0;
            font-weight: 500;
        }

        small {
            color: #9ca3af;
            margin-top: 5px;
        }
    }

    .file-preview {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .file-info {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .remove-file-btn {
            color: #dc2626;
            padding: 0;
            margin-left: 10px;
        }
    }

    .invalid-feedback {
        color: #dc2626;
        font-size: 0.875rem;
        margin-top: 5px;
    }
}

.backdropWrapper {
    position: fixed;
    background: #0000003d;
    width: 100vw;
    height: 100vh;
    inset: 0;
    z-index: 999999 !important;
}

.loaderWrapper {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 80px;
    height: 80px;
}

.loaderWrapper div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #000000d9;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loaderWrapper div:nth-child(1) {
    left: 8px;
    animation: load1 0.6s infinite;
}

.loaderWrapper div:nth-child(2) {
    left: 8px;
    animation: load2 0.6s infinite;
}

.loaderWrapper div:nth-child(3) {
    left: 32px;
    animation: load2 0.6s infinite;
}

.loaderWrapper div:nth-child(4) {
    left: 56px;
    animation: load3 0.6s infinite;
}

@keyframes load1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes load3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes load2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

.badge-container {
    border-radius: 8px;
    padding: 10px 16px;
    display: inline-block;
    border: 1px solid #011527;
    background-color: #DEEBF1B2;
    cursor: pointer;

    .badge-text {
        color: #001426;
    }
}

.tabs-wrapper {
    display: inline-block;
    border: 1.5px solid #011527;
    border-radius: 8px;

    .tabs-container {
        padding: 10px 16px;
        display: inline-block;
        background-color: #ffffff;
        border-right: 1.5px solid #011527;
        cursor: pointer;

        &.active {
            background-color: #DEEBF1B2;
            font-weight: 500;
        }

        &:last-child {
            border-right: unset;
        }

        &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        .tabs-text {
            color: #001426;
        }
    }
}

.tippy-hover-content {
    background-color: black;
    color: white;
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 14px;
}


.table-toolbar-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .toolbar-wrapper {
        display: flex;
        gap: 10px;

        .toolbar-item {
            font-size: 20px;
            background: #CCE7FF;
            padding: 10px;
            border-radius: 4px;
            line-height: 10px;
            display: inline-block;
            cursor: pointer;
        }
    }
}